<template>
  <div class="auth">
    <div v-if="showLogin">
      <div class="auth__container">
        <div class="title">Вход</div>
        <div class="info">Введите логин и пароль</div>
        <input type="text" placeholder="Логин" v-model="login.login" />
        <input type="password" placeholder="Пароль" v-model="login.password" />
        <button class="btn btn-auth btn-gradient" @click="onLogin">Войти</button> 
        <div class="reset" @click="resetPassword">Забыли пароль?</div>
      </div>
    </div>
    <div v-else>
      <div class="auth__container">
        <div class="title">Восстановление пароля</div>
        <div class="info">Введите E-mail для восстановления пароля</div>
        <input type="text" placeholder="E-mail" v-model="reset.email">
        <button class="btn btn-auth btn-gradient btn-reset" @click="onReset">Восстановить пароль</button>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  name: "AuthMain",
  data() {
    return {
      showLogin: true,
      login: {
        login: "",
        password: "",
      },
      reset: {
        email: ''
      }
    };
  },
  mounted() {
    this.$store.dispatch("checkToken");
  },
  methods: {
    onReset() {
      Axios
        .post(`/api/email`, {email: this.reset.email, platform: 'web'})
        .then(res => {
          this.$bus.$emit('showNotification', {message: 'Письмо отправлено на вашу почту!', status: 'success'});
        })
        .catch(err => {
          console.error(err.response);
          this.$bus.$emit('showNotification', {message: err.response.data, status: 'danger'});
        });
    },
    onLogin() {
      Axios
        .post(`/api/auth/login`, this.login, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((res) => {
          this.$store.dispatch("createToken", res.data);
          this.$router.push("/admin");
        })
        .catch((err) => {
          console.error(err.response);
          this.$bus.$emit('showNotification', {message: err.response.data, status: 'danger'});
        });
    },
    resetPassword() {
      this.showLogin = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.auth {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #00325C;
  text-align: center;
  div {
    .auth__container {
      display: flex;
      flex-direction: column;
      width: 350px;
      .title {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .info {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .reset {
        margin-top: 10px;
        font-size: 12px;
        cursor: pointer;
      }
      .btn {
        margin: 10px auto;
        width: 50%;
      }
      input {
        margin-bottom: 15px;
        &[type="submit"] {
          width: 45%;
          margin: 15px 95px;
          &.btn-reset {
            width: 58%;
            margin: 15px 73px;
          }
        }
      }
    }
  }
}
</style>