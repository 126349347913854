<template>
  <AuthMain />
</template>

<script>
import axios from 'axios';
import AuthMain from '@/components/auth/AuthMain';

export default {
  name: 'Auth',
  components: {
    AuthMain
  }
}
</script>

<style>

</style>